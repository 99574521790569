<template>
  <b-modal :id="$route.name + 'CreateModal'" title="Добавить тип населенного пункта" size="md" hide-footer
    no-enforce-focus :no-close-on-backdrop="true" @show="opened">
    <b-row>
      <b-col cols="12">
        <b-row>
          <b-col cols="6">
            <label>Название</label>
            <b-input v-model="village_type.name" placeholder="Введите название" />
          </b-col>
          <b-col cols="6">
            <label>Короткое название</label>
            <b-input v-model="village_type.short_name" placeholder="Введите короткое название" />
          </b-col>
        </b-row>
        <b-row>
          <b-col class="mt-2" cols="12">
            <div class="status">
              <label class="mr-1" for="">Статус</label>
              <b-form-checkbox v-model="village_type.is_active" checked="true" name="check-button" switch inline>
                {{ this.village_type.is_active ? 'Активный' : 'Неактивный' }}
              </b-form-checkbox>
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="12" class="d-flex justify-content-between">
        <b-button :disabled="submitButtonDisabled" class="mt-2 col-md-5" variant="primary" @click="createVillageType()">
          Добавить
        </b-button>
        <b-button class="mt-2 col-md-5" variant="secondary" @click="btnCancel()">
          Отмена
        </b-button>
      </b-col>
    </b-row>
  </b-modal>
</template>
<script>
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    vSelect,
    ToastificationContent,
  },
  data() {
    return {
      village_type: {
        name: null,
        short_name: null,
        is_active: true
      },
      submitButtonDisabled: false
    }
  },
  methods: {
    createVillageType() {
      this.submitButtonDisabled = true
      this.village_type.is_active = this.village_type.is_active ? 1 : 0
      this.$http
        .post('addresses/village-type', this.village_type)
        .then(res => {
          this.btnCancel()
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Успех!',
              icon: 'CheckIcon',
              variant: 'success',
              text: `Тип населенного пункта успешно создан!`,
            },
          })
          this.$bvModal.hide(this.$route.name + 'CreateModal')
          this.$emit('refresh')
        })
        .catch(err => {
          this.village_type.is_active = res.data.is_active === 1 ? true : false
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Некорректные данные!',
              icon: 'XIcon',
              variant: 'danger',
              text: err.response.data.errors,
            },
          })
        })
        .finally(() => {
          this.submitButtonDisabled = false
        })
    },
    opened() {
      this.clearData()
    },
    btnCancel() {
      this.$bvModal.hide(this.$route.name + 'CreateModal')
      this.clearData()
    },
    clearData() {
      this.village_type = {
        name: null,
        short_name: null,
        is_active: true
      }
    }
  }
}
</script>