<template>
  <div>
      <div class="d-flex justify-content-center align-items-center" v-if="showPreloader || $store.state.refresh.fetchingNewData || $store.state.refresh.waiting_filter" style="height: 90vh;">
          <b-spinner variant="primary" label="Text Centered" />
      </div>
      <div v-else>
           <table-village-type :village_types="village_types" @editForm="editForm"></table-village-type>
      </div>
      <modal-create-village-type @refresh="refresh"></modal-create-village-type>
      <modal-village-type-edit :village_type_id="id" @refresh="refresh"></modal-village-type-edit>
  </div>
  </template>
  
  <script>
  
  import tableVillageType from "@/views/component/Table/tableVillageType.vue"
  import modalCreateVillageType from "@/views/component/Modal/ModalVillageType/modalCreateVillageType.vue"
  import modalVillageTypeEdit from "@/views/component/Modal/ModalVillageType/modalVillageTypeEdit.vue"
  export default {
      components:{
        tableVillageType,
        modalCreateVillageType,
        modalVillageTypeEdit
      },
      data(){
          return{
              village_types: [],
              showPreloader: false,
              id: '',
          }
      },
      methods: {
        editForm(id){
            this.id = id
            setTimeout(this.openModal, 0);
        },
        openModal(){
            this.$bvModal.show('villageTypeEdit')
        },
          refresh(){
              this.showPreloader = true
              this.$http
              .get(`${this.$route.name}`)
              .then(res =>{
                  this.village_types = res.data
                  this.$store.commit('pageData/setdataCount', this.village_types.length)
                  this.showPreloader = false
                  this.$store.commit('REFRESH_DATA', false)
              })
          },
      },
      mounted(){
          this.$store.commit('pageData/setdataCount', null)
          this.refresh()
      },
      computed: {
          fetchingNewData(){
              return this.$store.state.refresh.fetchingNewData
          }
      },
      watch: {
          fetchingNewData(val){
              if (val){
                  this.$http
                  .get(`${this.$route.name}`)
                  .then(res =>{
                      this.village_types = res.data
                      this.$store.commit('pageData/setdataCount', this.village_types.length)
                      this.$store.commit('REFRESH_DATA', false)
                  })
                  }
              }
      }
  }
  </script>
  
  <style>
  
  </style>